import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "jasonDinsmore",
  "categories": ["rails", "ruby"],
  "date": "2021-04-20",
  "path": "/blog/Whats-Cooking-in-Rails-7",
  "summary": "Now that Rails 6.1 has been released, we are looking ahead to what Rails 7 has in store for us.",
  "title": "What's Cooking in Rails 7?",
  "image": "./ogp.png",
  "seo": {
    "og": {
      "type": "article",
      "title": "What's Cooking in Rails 7?",
      "description": "Now that Rails 6.1 has been released, we are looking ahead to what Rails 7 has in store for us."
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia",
      "creator": "@dinjas"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A new version of Rails always brings new and exciting features. This writeup takes a look at some of the things Rails 7 has in store for us.`}</p>
    <p>{`To find these examples, I dug through the `}<a parentName="p" {...{
        "href": "https://edgeguides.rubyonrails.org/7_0_release_notes.html"
      }}>{`Changelogs`}</a>{` and selected some of the features that have me most excited so far, primarily based on how I currently use Rails.`}</p>
    <p>{`I encourage you to do the same - you may find something awesome that impacts a feature you use regulary or provides functionality you've been lying awake at night fantasizing about.`}</p>
    <p>{`For each feature, I have included the PR that introduced it and the GitHub user who submitted the PR.`}</p>
    <p>{`Note that several of these features have followup PRs from people making essential contributions by updating documentation, adding changelog entries, tweaking implementations, etc. If you are one of those folks, please know that while you're not given explicit credit here, we are very grateful for all you do! 🙏`}</p>
    <h1>{`Railties`}</h1>
    <h2>{`The `}<em parentName="h2">{`main`}</em>{` Branch`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/40254"
      }}>{`PR 40254`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/prateekkish"
      }}>{`@prateekkish`}</a></h3>
    <p>{`When generating a new Rails project or plugin without the `}<inlineCode parentName="p">{`--skip-git`}</inlineCode>{` flag, the default branch of the created repository will now be `}<inlineCode parentName="p">{`main`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`master`}</inlineCode>{`. If you've got a different branch set in your git config's `}<inlineCode parentName="p">{`init.defaultBranch`}</inlineCode>{`, that default will be used instead.`}</p>
    <p>{`Rails began initializing the Git repository `}<a parentName="p" {...{
        "href": "https://guides.rubyonrails.org/v5.1.0/5_1_release_notes.html#railties-notable-changes"
      }}>{`in 5.1`}</a>{` via `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/pull/27632"
      }}>{`PR 27632`}</a>{` by `}<a parentName="p" {...{
        "href": "https://github.com/dixpac"
      }}>{`@dixpac`}</a>{`.`}</p>
    <h2><em parentName="h2">{`benchmark`}</em>{` Anywhere`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/40734"
      }}>{`PR 40734`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/semaperepelitsa"
      }}>{`@semaperepelitsa`}</a></h3>
    <p>{`This PR adds the `}<inlineCode parentName="p">{`ActiveSupport::Benchmarkable.benchmark`}</inlineCode>{` method to the `}<inlineCode parentName="p">{`Rails`}</inlineCode>{` namespace, which allows you to easily benchmark a block of code anywhere without having to declare a `}<inlineCode parentName="p">{`logger`}</inlineCode>{` or `}<inlineCode parentName="p">{`extend`}</inlineCode>{`/`}<inlineCode parentName="p">{`include`}</inlineCode>{` the `}<inlineCode parentName="p">{`ActiveSupport::Benchmarkable`}</inlineCode>{` module.`}</p>
    <p>{`To use the new functionality, you'd do something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Rails`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`benchmark`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Print bar'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`do`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# code to benchmark`}</span>{`
    `}<span parentName="code" {...{
            "className": "token number"
          }}>{`100.`}</span>{`times `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` puts `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'bar'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`After your code executes, the message you provided and the time it took the code to execute in milliseconds will be passed along to the Rails logger, ie: `}<inlineCode parentName="p">{`Print bar (0.6ms)`}</inlineCode>{`. The `}<inlineCode parentName="p">{`benchmark`}</inlineCode>{` call will return the return value of the block, `}<inlineCode parentName="p">{`100`}</inlineCode>{` in this case.`}</p>
    <h2>{`Support Stats For Stylesheets and ERB Views`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/40597"
      }}>{`PR 40597`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/joelhawksley"
      }}>{`@joelhawksley`}</a></h3>
    <p>{`If you've run `}<inlineCode parentName="p">{`rails stats`}</inlineCode>{`, you know that it provides various statistics about your app's codebase. This new functionality adds `}<inlineCode parentName="p">{`Views`}</inlineCode>{` and `}<inlineCode parentName="p">{`Stylesheets`}</inlineCode>{` to the output, providing stats for files in `}<inlineCode parentName="p">{`app/views`}</inlineCode>{` and `}<inlineCode parentName="p">{`app/assets/stylesheets`}</inlineCode>{`.`}</p>
    <p>{`Note that stylesheets need to have a `}<inlineCode parentName="p">{`.css`}</inlineCode>{` or `}<inlineCode parentName="p">{`.scss`}</inlineCode>{` extension, and view files need to have the `}<inlineCode parentName="p">{`.erb`}</inlineCode>{` extension in order to be processed.`}</p>
    <h1>{`ActionPack`}</h1>
    <h2>{`Redirect Back or To`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/40671"
      }}>{`PR 40671`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/dhh"
      }}>{`@dhh`}</a></h3>
    <p>{`Adds `}<inlineCode parentName="p">{`redirect_back_or_to(fallback, **)`}</inlineCode>{` as a nice shorthand for `}<inlineCode parentName="p">{`redirect_back(fallback_location:, **)`}</inlineCode>{`.`}</p>
    <p>{`This avoids having to specify the `}<inlineCode parentName="p">{`fallback_location`}</inlineCode>{` kwarg (keyword argument) to `}<inlineCode parentName="p">{`redirect_back`}</inlineCode>{`. `}<inlineCode parentName="p">{`redirect_back_or_to`}</inlineCode>{` will redirect the user back to whence they came if it can, and will redirect to the fallback location if it can't.`}</p>
    <p>{`The old syntax still works and hasn't been explicitly deprecated, but now `}<inlineCode parentName="p">{`redirect_back`}</inlineCode>{` calls `}<inlineCode parentName="p">{`redirect_back_or_to`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/blob/07233426e693ea0b8292050fb926de4d52fbfeeb/actionpack/lib/action_controller/metal/redirecting.rb#L69-L71"
      }}>{`under the hood`}</a>{`.`}</p>
    <h2>{`Unpermitted Parameter Context`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/41809"
      }}>{`PR 41809`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/bbuchalter"
      }}>{`@bbuchalter`}</a></h3>
    <p>{`This PR provides controller and request context when the parameters object is initialized so that when unpermitted attributes are logged, the controller, action, request, and filtered parameters will be logged as well.`}</p>
    <h1>{`ActionView`}</h1>
    <h2>{`Lazy Load Images`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/38452"
      }}>{`PR 38452`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/jonathanhefner"
      }}>{`@jonathanhefner`}</a></h3>
    <p>{`Currently, apps can take advantage of the HTML standard's lazy loading of images by manually specifying the `}<inlineCode parentName="p">{`loading`}</inlineCode>{` attribute as `}<inlineCode parentName="p">{`lazy`}</inlineCode>{` in calls to `}<inlineCode parentName="p">{`image_tag`}</inlineCode>{`. This new configuration allows you to set the default to `}<inlineCode parentName="p">{`"lazy"`}</inlineCode>{` app-wide, so that the attribute will only need to be specified for places you want an image to be eager loaded.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "600px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "60.122699386503065%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADfklEQVQoz3WSbVDTBRzHf/9N3YwHw7GxJ6LxuE3W/tvYAzTGtkbhFeSShAGNY/tvboOxAUcGiwOUbXmaAmnxwjtBxoB5KnbZi9qlnXZxV0d6Zr0ou7q6sxfVeb5Ib8P/r9Nelp9Xn1efN98vwBNordoOxQBQxgDwW/PhXtpCIEbh6kKhYyNVfuPqHBevr0l/+yypa/xlvRW+WqtlXDxF/n/MrSkAIQCwAeDIa5VMzJqZy8MAXx6D9p8ubMXfr+ThzVVO9tvlPPwhrb2Jd1XQbuPDzGEJ8Z+YU5kPZM6/jt9PElOvlD5SJqINzsaemvnkvR14+QT7wTeLLPp6gkXf+liyeWVFHbbagKVUwJNBXIcb77wMQw2CjrkuxdfzPcofoz0Nd8OttTjdKafDbSQdpSo2L0XVmJiUzu05GwEAAiBk5DGGGvjERqwRVnwqqOcCLHlrGO93VMMBqzi87NPih4N1eD6kx1SvBiPtdfSow4ijdhU90qLILLpIjJrLqfzZYjd58FkrjLwggrcsIphqljBfFDOgr45HNIoIMHGh5LSL/Pt82IALXjJzZr/qYdKvoU+5FDjuacblPh294n8Oj7dVYxMlXJVNlz2QRMUeGDTx9YPGIkm8RQKpN2uJYYuIFW8ph7GXSkIrAS0m/JpMMqDFpYCWnnfJ6QtxN/1pOk3HR4I4uU955+TA3mtv+JSHHPtl5wbaySYYtvLvj+1+5t4Bi8BztEsO9XyAW+82w/Q+6UIyUIMJrzK74JLRS341naB20ReP9NHzi0m630dlg3bDbMShCVHd1cFVnYk/66jPBf/znD/6G7gYNgtw0CxY8xo4+kerTrdJv0j1qvF00Ly5OkXRi0ETznvIh2fc1ZkPqFoca6nCYYs47KGU3aqp0p93Hi3S2yZlAE7NzmhvPQ8DxkL0GXnoMnCxq4ZzO/a6PHOiowLPnRzHaxvfbUYCndnxPVUYbZVhzF6GozaBfbS7AmAC2OpY2Yb8cGnq8T00TNjSqSoYoQyFlz0Gzq89Os79bi3nzwm79M6EvTI7sFtKB1/VYcAswiGb+HbIIr7kVBekTUXAL3n8LwDoJ/IEbxflFrtzCajLB3DquPDX5xSYROwcuzSXbykEdseuHJ5T9bSsSQgaSrk15tXtWLdXbLN9FN8LbsW27T49d0sQZ6AyIgQYYoH4oBBKenLgH68sULPFCj4cAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "An eager beaver",
        "title": "An eager beaver",
        "src": "/static/96cf9fd94187fd4f47029d68a8ef5a78/0a47e/beaver.png",
        "srcSet": ["/static/96cf9fd94187fd4f47029d68a8ef5a78/222b7/beaver.png 163w", "/static/96cf9fd94187fd4f47029d68a8ef5a78/ff46a/beaver.png 325w", "/static/96cf9fd94187fd4f47029d68a8ef5a78/0a47e/beaver.png 600w"],
        "sizes": "(max-width: 600px) 100vw, 600px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2>{`Tag Attributes From Hash`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/40657"
      }}>{`PR 40657`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/seanpdoyle"
      }}>{`@seanpdoyle`}</a></h3>
    <p>{`This cool addition provides a slick way to tranform a hash of attributes into attributes on an ERB tag.`}</p>
    <p>{`For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`%`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` tag`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`attributes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'percent-loaded'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` role`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'progressbar'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` aria`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` valuenow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'75'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`%>>`}</span></code></pre></div>
    <p>{`Would render:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`id`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`percent-loaded`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`role`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`progressbar`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`aria-valuenow`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`75`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`Perhaps not the greatest example, but if the hash were generated programmatically and required logic, this could be pretty useful.`}</p>
    <h1>{`ActiveModel`}</h1>
    <h2>{`Add Numericality In Range Validation`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/41022"
      }}>{`PR 41022`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/mpapis"
      }}>{`@mpapis`}</a></h3>
    <p>{`When validating `}<inlineCode parentName="p">{`numericality`}</inlineCode>{` of a model attribute, this new syntax lets you specify a range for the numericality validator versus having to sandwich between the `}<inlineCode parentName="p">{`greater_than_or_equal_to`}</inlineCode>{` and `}<inlineCode parentName="p">{`less_than_or_equal_to`}</inlineCode>{` options.`}</p>
    <p>{`Example usage:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`validates `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:latitude`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` numericality`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`90.`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`.90`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h1>{`ActiveRecord`}</h1>
    <h2>{`Invert a `}<em parentName="h2">{`where`}</em>{` Clause`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/40249"
      }}>{`PR 40249`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/kddeisz"
      }}>{`@kddeisz`}</a></h3>
    <p>{`This addition provides a handy way to get at the inverse of a where clause by via `}<inlineCode parentName="p">{`invert_where`}</inlineCode>{`.`}</p>
    <p>{`For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`good_students `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Student`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`grade`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80.`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`.100`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT \\"students\\".* FROM \\"students\\" WHERE \\"students\\".\\"grade\\" BETWEEN 80 AND 100`}</span>{`

bad_students `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` good_students`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`invert_where
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# SELECT \\"students\\".* FROM \\"students\\" WHERE NOT (\\"students\\".\\"grade\\" BETWEEN 80 AND 100)`}</span></code></pre></div>
    <h2>{`Exclude a Record From Results`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/41439"
      }}>{`PR 41439`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/GlenCrawford"
      }}>{`@GlenCrawford`}</a></h3>
    <p>{`Ever need a way to get all records matching some condition except a record you already have? `}<inlineCode parentName="p">{`excluding`}</inlineCode>{` may be your jam.`}</p>
    <p>{`Instead of:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`other_users `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`rating`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`not`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` primary_user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`You can:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`other_users `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`where`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`rating`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`80.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`excluding`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`primary_user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`Build or Create Association on Has One Through`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/40007"
      }}>{`PR 40007`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/perezperret"
      }}>{`@perezperret`}</a></h3>
    <p>{`Enables the `}<inlineCode parentName="p">{`build_association`}</inlineCode>{` and `}<inlineCode parentName="p">{`create_association`}</inlineCode>{` functionality for `}<inlineCode parentName="p">{`has_one through:`}</inlineCode>{` relations. Previously these were not available on `}<inlineCode parentName="p">{`through`}</inlineCode>{` associations.`}</p>
    <p>{`For example, if you had:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Dog`}</span>{`
  has_many `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:toys`}</span>{`
  has_one `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:toy_box`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Toy`}</span>{`
  belongs_to `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:dog`}</span>{`
  has_one `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:toy_box`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` through`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:dog`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ToyBox`}</span>{`
  belongs_to `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:dog`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <p>{`You'll now be able to:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`toy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`build_toy_box
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <ToyBox:0x00007f572007e170 id: nil, dog_id: 3>`}</span></code></pre></div>
    <p>{`Or:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`toy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`create_toy_box
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# <ToyBox:0x00005601f2ac09a0 id: 5, dog_id: 3>`}</span></code></pre></div>
    <h2>{`Respect Column Type When Calculating Average`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/40351"
      }}>{`PR 40351`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/schmijos"
      }}>{`@schmijos`}</a></h3>
    <p>{`In prior versions of Rails, calling `}<inlineCode parentName="p">{`ActiveRecord::Caculations#calculate`}</inlineCode>{` with `}<inlineCode parentName="p">{`:average`}</inlineCode>{` would result in a `}<inlineCode parentName="p">{`BigDecimal`}</inlineCode>{`, even if a column was of a `}<inlineCode parentName="p">{`Float`}</inlineCode>{` or `}<inlineCode parentName="p">{`Integer`}</inlineCode>{` type.`}</p>
    <p>{`For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Coordinate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`last`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`longitude`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Float`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Coordinate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`calculate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:average`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:longitude`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# BigDecimal`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Coordinate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`calculate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:average`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:longitude`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 0.13002356e3`}</span></code></pre></div>
    <p>{`With the new behavior, it honors the type of the database column:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`Coordinate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`calculate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:average`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:longitude`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Float`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Coordinate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`calculate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:average`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:longitude`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# 130.02356`}</span></code></pre></div>
    <p>{`One of the motivators for this change was that JSON conversions of `}<inlineCode parentName="p">{`BigDecimal`}</inlineCode>{` result in a string value, whereas `}<inlineCode parentName="p">{`Float`}</inlineCode>{` results in a numeric value.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}><span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` avg_longitude`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`130.02356`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_json`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# { "avg_longitude" => "130.02356" }`}</span>{`
`}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`parse`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` avg_longitude`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`130.02356`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_json`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# { "avg_longitude" => 130.02356 }`}</span></code></pre></div>
    <h2>{`Automatically Encrypt/Decrypt a Model Attribute`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://github.com/rails/rails/pull/41659"
      }}>{`PR 41659`}</a>{` by `}<a parentName="h3" {...{
        "href": "https://github.com/jorgemanrubia"
      }}>{`@jorgemanrubia`}</a></h3>
    <p>{`Finally, we get to `}<inlineCode parentName="p">{`encrypts`}</inlineCode>{`.`}</p>
    <p>{`This is deserving of a post all its own. The TLDR; version is that this feature adds a mechanism to auto-encrypt/decrypt an `}<inlineCode parentName="p">{`ActiveRecord`}</inlineCode>{` attribute by declaring `}<inlineCode parentName="p">{`encrypts attr_name`}</inlineCode>{` in the model. When a record is written to the database, it will automatically be encrypted, and when it is loaded from the database, the attribute will automatically be decrypted.`}</p>
    <p><inlineCode parentName="p">{`encrypts`}</inlineCode>{` also provides a workflow for changing the encryption scheme being used, without needing to re-encrypt all previously encrypted records under the new scheme.`}</p>
    <p>{`Data can be encrypted in a non-deterministic manner (encrypting the same text twice will result in different ciphertexts), or can be encrypted deterministically - which allows querying the encrypted attribute.`}</p>
    <p>{`Other niceties are the capability to encrypt `}<inlineCode parentName="p">{`Action Text`}</inlineCode>{` and to filter encrypted attributes from the application log files.`}</p>
    <p>{`It really does quite a lot and provides several ways to customize behavior, depending on your needs.`}</p>
    <h1>{`What Else?`}</h1>
    <p>{`I touched on the things I am most excited about so far, but there is other great work underway. `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/blob/main/actionpack/CHANGELOG.md"
      }}>{`ActionPack`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/blob/main/actionview/CHANGELOG.md"
      }}>{`ActionView`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/blob/main/activerecord/CHANGELOG.md"
      }}>{`ActiveRecord`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://github.com/rails/rails/blob/main/activesupport/CHANGELOG.md"
      }}>{`ActiveSupport`}</a>{` in particular, all have rapidly growing lists of changes.`}</p>
    <p>{`Thanks again to all the contributors who are making this next release possible. It's shaping up nicely!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      